// extracted by mini-css-extract-plugin
export var backgroundAnimation = "akcja-gorzka-layout-module--backgroundAnimation--5e55b";
export var contact = "akcja-gorzka-layout-module--contact--a0b72";
export var container = "akcja-gorzka-layout-module--container--f1195";
export var content = "akcja-gorzka-layout-module--content--70b94";
export var errorBlink = "akcja-gorzka-layout-module--error-blink--451ac";
export var hasHeader = "akcja-gorzka-layout-module--has-header--db4f6";
export var header = "akcja-gorzka-layout-module--header--f4476";
export var headerContent = "akcja-gorzka-layout-module--header-content--7ca96";
export var layout = "akcja-gorzka-layout-module--layout--e3e50";
export var left = "akcja-gorzka-layout-module--left--f79b6";
export var mainLayout = "akcja-gorzka-layout-module--main-layout--bdab2";
export var right = "akcja-gorzka-layout-module--right--374ed";
export var thanks = "akcja-gorzka-layout-module--thanks--5f3c9";