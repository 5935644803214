import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
    layout,
    container,
    contact,
    left,
    right,
    content,
    header,
    hasHeader,
    thanks,
    headerContent,
    mainLayout,
} from './akcja-gorzka-layout.module.scss';
import { grid } from '../styles/grid.module.scss';

import staticFiles from '../config/static-files';

import MainLayout, { IMainLayoutProps } from './main-layout';
import ContestThankYou, { IContestThankYouProps } from '../components/organisms/contest-thank-you';
import AkcjaGorzkaContact from '../components/organisms/akcja-gorzka-contact';

const leftImg = '../assets/images/akcja-gorzka/pasek.png';
const rightImg = '../assets/images/akcja-gorzka/winyl.png';

interface IAkcjaGorzkaLayout extends IMainLayoutProps {
    children?: React.ReactNode;
    headerProps?: IContestThankYouProps;
    showSideImages?: boolean;
}

const AkcjaGorzkaLayout: React.FC<IAkcjaGorzkaLayout> = ({
    children,
    className = '',
    headerProps,
    showSideImages = false,
    ...rest
}) => {
    return (
        <MainLayout
            {...rest}
            className={`${layout} ${headerProps ? hasHeader : ''}`}
            containerClassName={mainLayout}
        >
            <div className={`${headerProps ? grid : ''} ${header}`}>
                <div className={headerContent}>
                    {showSideImages && (
                        <>
                            <div className={left}>
                                <StaticImage src={leftImg} alt="" />
                            </div>
                            <div className={right}>
                                <StaticImage src={rightImg} alt="" />
                            </div>
                        </>
                    )}

                    {headerProps && <ContestThankYou {...headerProps} className={thanks} />}
                </div>
            </div>
            <div className={`${container} ${grid}`}>
                <div className={`${className} ${content}`}>{children}</div>
                <AkcjaGorzkaContact
                    className={contact}
                    regulationsUrl={staticFiles.akcjaGorzkaRegulations}
                />
            </div>
        </MainLayout>
    );
};

export default AkcjaGorzkaLayout;
